<template>
    <div>
        <b-alert :show="not_found" variant="danger">
            <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
                $t("PRODUCT_PAGE.NOT_FOUND")
            }}</span>
        </b-alert>
        <!--begin::Card-->
        <div v-if="!not_found" class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3 pr-1">
                <div class="col card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">
                        {{ $t("PRODUCT_PAGE.VIEW") }}
                    </h3>
                    <span
                        class="text-muted font-weight-bold font-size-sm mt-1"
                        >{{ $t("PRODUCT_PAGE.SUBTITLE") }}</span
                    >
                </div>
                <div
                    class="col-lg-8 col-xl-8 card-title align-items-center justify-content-center"
                    style="background-color: #575757"
                >
                    <h3
                        class="card-label font-weight-bolder"
                        style="color: #fff"
                    >
                        XIPHOO Product Page Builder
                    </h3>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
                <!--begin::Body-->
                <div class="card-body row d-flex justify-content-center">
                    <div
                        class="col-lg-5 col-xl-4 border align-items-center d-flex justify-content-center"
                    >
                        <iframe
                            height="100%"
                            width="100%"
                            :src="iframeLink"
                            :key="iframeUpdate"
                        ></iframe>
                    </div>
                    <form class="form col-lg-7 col-xl-8" id="product_page_form">
                        <fieldset id="edit_wrapper">
                            <div
                                v-if="edit_modal_visible"
                                class="modal"
                                tabindex="-1"
                                role="dialog"
                                style="display: block"
                            >
                                <div
                                    class="modal-dialog modal-dialog-centered"
                                    role="document"
                                >
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">
                                                {{ $t("PRODUCT_PAGE.EDIT") }}
                                            </h5>
                                            <button
                                                type="button"
                                                class="close"
                                                @click="
                                                    edit_modal_visible = false
                                                "
                                            >
                                                <span aria-hidden="true"
                                                    >&times;</span
                                                >
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div
                                                class="form-group row"
                                                v-if="
                                                    current_modal_values.type ===
                                                        'BUTTON' ||
                                                    current_modal_values.type ===
                                                        'BRAND_LOGO' ||
                                                    current_modal_values.type ===
                                                        'BACKGROUND'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        current_modal_values.type ===
                                                        "BRAND_LOGO"
                                                            ? "Logo"
                                                            : $t("COLOR.IMAGE")
                                                    }}</label
                                                >
                                                <div class="col-lg-9 col-xl-6">
                                                    <div
                                                        class="image-input image-input-outline"
                                                        id="kt_profile_avatar"
                                                    >
                                                        <div
                                                            class="image-input-wrapper"
                                                            :style="{
                                                                backgroundImage: `url(${
                                                                    current_modal_values.image !==
                                                                    ''
                                                                        ? current_modal_values.image
                                                                        : default_image
                                                                })`,
                                                            }"
                                                        ></div>
                                                        <label
                                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="change"
                                                        >
                                                            <i
                                                                class="fa fa-pen icon-sm text-muted"
                                                            ></i>
                                                            <input
                                                                type="file"
                                                                name="profile_avatar"
                                                                accept=".png, .jpg, .jpeg, .svg"
                                                                @change="
                                                                    changedImage(
                                                                        $event
                                                                    )
                                                                "
                                                            />
                                                        </label>
                                                        <span
                                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="remove"
                                                            @click="
                                                                clearImage()
                                                            "
                                                        >
                                                            <i
                                                                class="ki ki-bold-close icon-xs text-muted"
                                                            ></i>
                                                        </span>
                                                    </div>
                                                    <span
                                                        class="form-text text-muted"
                                                        >{{
                                                            $t(
                                                                "COLOR.IMAGE_FILETYPES"
                                                            )
                                                        }}, svg</span
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row"
                                                v-if="
                                                    current_modal_values.type !==
                                                        'BRAND_LOGO' &&
                                                    current_modal_values.type !==
                                                        'COLOR_OPTION' &&
                                                    current_modal_values.type !==
                                                        'BACKGROUND'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        $t("PRODUCT_PAGE.NAME")
                                                    }}</label
                                                >
                                                <div class="col-lg-7 col-xl-7">
                                                    <textarea
                                                        ref="text"
                                                        name="text"
                                                        class="form-control form-control-md form-control-solid"
                                                        type="text"
                                                        v-model="
                                                            current_modal_values.name
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row"
                                                v-if="
                                                    current_modal_values.type !==
                                                        'BRAND_LOGO' &&
                                                    current_modal_values.type !==
                                                        'COLOR_OPTION'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        current_modal_values.type !==
                                                        "BACKGROUND"
                                                            ? $t(
                                                                  "PRODUCT_PAGE.DESCRIPTION"
                                                              )
                                                            : $t(
                                                                  "PRODUCT_PAGE.BACKGROUND_COLOR"
                                                              )
                                                    }}</label
                                                >
                                                <div class="col-lg-8 col-xl-8">
                                                    <textarea
                                                        ref="text"
                                                        name="text"
                                                        class="form-control form-control-md form-control-solid"
                                                        type="text"
                                                        v-model="
                                                            current_modal_values.text
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row"
                                                v-if="
                                                    current_modal_values.type !==
                                                    'BACKGROUND'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        current_modal_values.type ===
                                                            "COLOR_OPTION" ||
                                                        current_modal_values.type ===
                                                            "BUTTON"
                                                            ? $t(
                                                                  "PRODUCT_PAGE.TEXTPOSITIONING"
                                                              )
                                                            : $t(
                                                                  "PRODUCT_PAGE.POSITIONING"
                                                              )
                                                    }}
                                                </label>
                                                <div
                                                    class="col-lg-8 col-xl-8 d-flex align-items-center"
                                                >
                                                    <!-- Button für linksbündige Ausrichtung -->
                                                    <div
                                                        class="btn-group"
                                                        role="group"
                                                        aria-label="Text alignment"
                                                    >
                                                        <button
                                                            class="btn btn-outline-primary d-flex justify-content-center align-items-center"
                                                            :class="{
                                                                active:
                                                                    current_modal_values
                                                                        .css_properties
                                                                        .textAlignment ===
                                                                    'left',
                                                            }"
                                                            @click.prevent="
                                                                setTextAlignment(
                                                                    'left'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                class="svg-icon svg-icon-md svg-icon-secondary mr-0"
                                                            >
                                                                <inline-svg
                                                                    src="media/svg/icons/Text/Align-left.svg"
                                                                ></inline-svg>
                                                            </span>
                                                        </button>

                                                        <!-- Button für zentrierte Ausrichtung -->
                                                        <button
                                                            class="btn btn-outline-primary d-flex justify-content-center align-items-center"
                                                            :class="{
                                                                active:
                                                                    current_modal_values
                                                                        .css_properties
                                                                        .textAlignment ===
                                                                    'center',
                                                            }"
                                                            @click.prevent="
                                                                setTextAlignment(
                                                                    'center'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                class="svg-icon svg-icon-md svg-icon-secondary mr-0"
                                                            >
                                                                <inline-svg
                                                                    src="media/svg/icons/Text/Align-center.svg"
                                                                ></inline-svg>
                                                            </span>
                                                        </button>

                                                        <!-- Button für rechtsbündige Ausrichtung -->
                                                        <button
                                                            class="btn btn-outline-primary d-flex justify-content-center align-items-center"
                                                            :class="{
                                                                active:
                                                                    current_modal_values
                                                                        .css_properties
                                                                        .textAlignment ===
                                                                    'right',
                                                            }"
                                                            @click.prevent="
                                                                setTextAlignment(
                                                                    'right'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                class="svg-icon svg-icon-md svg-icon-secondary mr-0"
                                                            >
                                                                <inline-svg
                                                                    src="media/svg/icons/Text/Align-right.svg"
                                                                ></inline-svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row justify-content-center align-items-center"
                                                v-if="
                                                    current_modal_values.type ===
                                                        'BRAND_LOGO' ||
                                                    current_modal_values.type ===
                                                        'COLOR_OPTION' ||
                                                    current_modal_values.type ===
                                                        'TEXT'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        $t(
                                                            "PRODUCT_PAGE.BACKGROUND_COLOR"
                                                        )
                                                    }}</label
                                                >
                                                <div class="col-lg-8 col-xl-8">
                                                    <input
                                                        ref="link"
                                                        name="link"
                                                        class="form-control form-control-md form-control-solid"
                                                        type="text"
                                                        v-model="
                                                            current_modal_values
                                                                .css_properties
                                                                .backgroundColor
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row"
                                                v-if="
                                                    current_modal_values.type !==
                                                        'BRAND_LOGO' &&
                                                    current_modal_values.type !==
                                                        'BACKGROUND'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        $t("PRODUCT_PAGE.FONT")
                                                    }}</label
                                                >
                                                <div
                                                    class="col-lg-8 col-xl-8 d-flex justify-content-between align-items-center"
                                                >
                                                    <select
                                                        class="form-control mr-3"
                                                        v-model="
                                                            current_modal_values
                                                                .css_properties
                                                                .fontFamily
                                                        "
                                                    >
                                                        <option
                                                            value="Arial, sans-serif"
                                                        >
                                                            Arial
                                                        </option>
                                                        <option
                                                            value="'Arial Black', Gadget, sans-serif"
                                                        >
                                                            Arial Black
                                                        </option>
                                                        <option
                                                            value="'Comic Sans MS', cursive, sans-serif"
                                                        >
                                                            Comic Sans MS
                                                        </option>
                                                        <option
                                                            value="'Courier New', Courier, monospace"
                                                        >
                                                            Courier New
                                                        </option>
                                                        <option
                                                            value="Georgia, serif"
                                                        >
                                                            Georgia
                                                        </option>
                                                        <option
                                                            value="Impact, Charcoal, sans-serif"
                                                        >
                                                            Impact
                                                        </option>
                                                        <option
                                                            value="'Lucida Sans Unicode', 'Lucida Grande', sans-serif"
                                                        >
                                                            Lucida Sans Unicode
                                                        </option>
                                                        <option
                                                            value="'Lucida Console', Monaco, monospace"
                                                        >
                                                            Lucida Console
                                                        </option>
                                                        <option
                                                            value="'Palatino Linotype', 'Book Antiqua', Palatino, serif"
                                                        >
                                                            Palatino Linotype
                                                        </option>
                                                        <option
                                                            value="Tahoma, Geneva, sans-serif"
                                                        >
                                                            Tahoma
                                                        </option>
                                                        <option
                                                            value="'Times New Roman', Times, serif"
                                                        >
                                                            Times New Roman
                                                        </option>
                                                        <option
                                                            value="'Trebuchet MS', Helvetica, sans-serif"
                                                        >
                                                            Trebuchet MS
                                                        </option>
                                                        <option
                                                            value="Verdana, Geneva, sans-serif"
                                                        >
                                                            Verdana
                                                        </option>
                                                        <option
                                                            value="Gill Sans, Geneva, sans-serif"
                                                        >
                                                            Gill Sans
                                                        </option>
                                                        <option
                                                            value="'MS Sans Serif', Geneva, sans-serif"
                                                        >
                                                            MS Sans Serif
                                                        </option>
                                                        <option
                                                            value="'MS Serif', 'New York', sans-serif"
                                                        >
                                                            MS Serif
                                                        </option>
                                                        <!-- Füge weitere Schriftarten nach Bedarf hinzu -->
                                                    </select>
                                                    <div
                                                        class="font-preview w-50"
                                                        v-bind:style="{
                                                            fontFamily:
                                                                current_modal_values
                                                                    .css_properties
                                                                    .fontFamily,
                                                        }"
                                                    >
                                                        {{
                                                            $t(
                                                                "PRODUCT_PAGE.FONT_PREVIEW"
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row"
                                                v-if="
                                                    current_modal_values.type !==
                                                        'BACKGROUND' &&
                                                    current_modal_values.type !==
                                                        'BRAND_LOGO'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        $t(
                                                            "PRODUCT_PAGE.TEXT_COLOR"
                                                        )
                                                    }}</label
                                                >
                                                <div class="col-lg-8 col-xl-8">
                                                    <input
                                                        ref="link"
                                                        name="link"
                                                        class="form-control form-control-md form-control-solid"
                                                        type="text"
                                                        v-model="
                                                            current_modal_values
                                                                .css_properties
                                                                .textColor
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row d-flex justify-content-center align-items-center"
                                                v-if="
                                                    current_modal_values.type !==
                                                    'BACKGROUND'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        $t(
                                                            "PRODUCT_PAGE.BORDER"
                                                        )
                                                    }}</label
                                                >
                                                <div class="col-lg-8 col-xl-8">
                                                    <div class="checkbox-list">
                                                        <label class="checkbox"
                                                            ><input
                                                                type="checkbox"
                                                                v-model="
                                                                    current_modal_values
                                                                        .css_properties
                                                                        .border
                                                                "
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row d-flex justify-content-center align-items-center"
                                                v-if="
                                                    current_modal_values.type !==
                                                    'BACKGROUND'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        $t(
                                                            "PRODUCT_PAGE.BORDER_COLOR"
                                                        )
                                                    }}</label
                                                >
                                                <div class="col-lg-8 col-xl-8">
                                                    <input
                                                        ref="link"
                                                        name="link"
                                                        class="form-control form-control-md form-control-solid"
                                                        type="text"
                                                        v-model="
                                                            current_modal_values
                                                                .css_properties
                                                                .borderColor
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row"
                                                v-if="
                                                    current_modal_values.type !==
                                                    'BACKGROUND'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >Link</label
                                                >
                                                <div class="col-lg-8 col-xl-8">
                                                    <input
                                                        ref="link"
                                                        name="link"
                                                        class="form-control form-control-md form-control-solid"
                                                        type="text"
                                                        v-model="
                                                            current_modal_values.link
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row d-flex justify-content-center align-items-center"
                                                v-if="
                                                    current_modal_values.type ===
                                                    'BRAND_LOGO'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >Sticky</label
                                                >
                                                <div class="col-lg-8 col-xl-8">
                                                    <div class="checkbox-list">
                                                        <label class="checkbox"
                                                            ><input
                                                                type="checkbox"
                                                                v-model="
                                                                    current_modal_values
                                                                        .css_properties
                                                                        .sticky
                                                                "
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group row"
                                                v-if="
                                                    current_modal_values.type ===
                                                    'COLOR_OPTION'
                                                "
                                            >
                                                <label
                                                    class="col-xl-4 col-lg-4 col-form-label text-right"
                                                    >{{
                                                        $t(
                                                            "PRODUCT_PAGE.COLOR_OPTION_NAME"
                                                        )
                                                    }}</label
                                                >
                                                <div class="col-lg-8 col-xl-8">
                                                    <div class="checkbox-list">
                                                        <label class="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                v-model="
                                                                    current_modal_values
                                                                        .options
                                                                        .model
                                                                "
                                                            />
                                                            <span></span>
                                                            {{
                                                                $t(
                                                                    "PRODUCT_PAGE.MODEL"
                                                                )
                                                            }}
                                                        </label>
                                                        <label class="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                v-model="
                                                                    current_modal_values
                                                                        .options
                                                                        .color
                                                                "
                                                            />
                                                            <span></span>
                                                            {{
                                                                $t(
                                                                    "PRODUCT_PAGE.COLOR"
                                                                )
                                                            }}
                                                        </label>
                                                        <label class="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                v-model="
                                                                    current_modal_values
                                                                        .options
                                                                        .size
                                                                "
                                                            />
                                                            <span></span>
                                                            {{
                                                                $t(
                                                                    "PRODUCT_PAGE.SIZE"
                                                                )
                                                            }}
                                                        </label>
                                                        <label class="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                v-model="
                                                                    current_modal_values
                                                                        .options
                                                                        .material
                                                                "
                                                            />
                                                            <span></span>
                                                            Material
                                                        </label>
                                                        <label class="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                v-model="
                                                                    current_modal_values
                                                                        .options
                                                                        .washing_instruction
                                                                "
                                                            />
                                                            <span></span>
                                                            {{
                                                                $t(
                                                                    "PRODUCT_PAGE.WASHING_INSTRUCTION"
                                                                )
                                                            }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button
                                                type="button"
                                                class="btn btn-secondary"
                                                @click="
                                                    edit_modal_visible = false
                                                "
                                            >
                                                {{ $t("PRODUCT_PAGE.CANCEL") }}
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                @click="saveItem()"
                                            >
                                                {{ $t("PRODUCT_PAGE.SAVE") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="edit_modal_visible"
                                class="modal-backdrop fade show"
                            ></div>
                            <div
                                v-for="(value, p_index) in product_page"
                                :key="p_index"
                            >
                                <p
                                    style="
                                        font-size: 1.3rem;
                                        font-weight: bold;
                                        margin-bottom: 1rem;
                                        color: #c69c6d;
                                    "
                                >
                                    {{ value.name }}
                                </p>
                                <hr />
                                <div
                                    class="form-group row"
                                    v-for="(item, index) in value.data"
                                    :key="index"
                                >
                                    <div
                                        class="col-lg-12 col-xl-12 d-flex justify-content-start"
                                    >
                                        <label
                                            class="col-lg-5 col-xl-5 col-form-label justif"
                                        >
                                            -
                                            {{ item.name }}
                                        </label>
                                        <label
                                            @click="editItem(item)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                            >
                                                <!-- Hier SVG oder Icon für Bearbeiten einfügen -->
                                                <inline-svg
                                                    src="media/svg/icons/Communication/Write.svg"
                                                    :title="$t('EDIT')"
                                                ></inline-svg>
                                            </span>
                                        </label>
                                        <label
                                            @click="confirmDelete(item.id)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                            >
                                                <!-- Hier SVG oder Icon für Löschen einfügen -->
                                                <inline-svg
                                                    src="media/svg/icons/General/Trash.svg"
                                                    :title="$t('DELETE')"
                                                ></inline-svg>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div
                                    class="form-group row"
                                    v-if="
                                        value.type === 'BUTTON' ||
                                        value.type === 'TEXT'
                                    "
                                >
                                    <div
                                        class="col-lg-12 col-xl-12 d-flex justify-content-start"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-sm btn-outline w-100 pe-auto font-weight-bold mb-4"
                                            style="font-size: 1.2rem"
                                            @click="newItem(value.type)"
                                        >
                                            {{
                                                $t("PRODUCT_PAGE.NEW_COMPONENT")
                                            }}
                                        </button>
                                    </div>
                                </div>
                                <div
                                    class="form-group row"
                                    v-if="value.type === 'GENERAL'"
                                >
                                    <div
                                        class="col-lg-12 col-xl-12 d-flex justify-content-start mt-3"
                                        v-if="!checkIfBrandLogoExists()"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-sm btn-outline w-100 pe-auto font-weight-bold mb-4"
                                            style="font-size: 1.2rem"
                                            @click="newItem('BRAND_LOGO')"
                                        >
                                            {{
                                                $t(
                                                    "PRODUCT_PAGE.NEW_BRAND_LOGO"
                                                )
                                            }}
                                        </button>
                                    </div>
                                    <div
                                        class="col-lg-12 col-xl-12 d-flex justify-content-start"
                                        v-if="!checkIfBackgroundExists()"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-sm btn-outline w-100 pe-auto font-weight-bold mb-4"
                                            style="font-size: 1.2rem"
                                            @click="newItem('BACKGROUND')"
                                        >
                                            {{
                                                $t(
                                                    "PRODUCT_PAGE.NEW_BACKGROUND"
                                                )
                                            }}
                                        </button>
                                    </div>
                                    <div
                                        class="col-lg-12 col-xl-12 d-flex justify-content-start"
                                        v-if="!checkIfColorOptionExists()"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-sm btn-outline w-100 pe-auto font-weight-bold mb-4"
                                            style="font-size: 1.2rem"
                                            @click="newItem('COLOR_OPTION')"
                                        >
                                            {{
                                                $t(
                                                    "PRODUCT_PAGE.NEW_INFORMATION_COMPONENT"
                                                )
                                            }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {
    ADD_BREADCRUMB,
    SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module"
// eslint-disable-next-line no-unused-vars
import KTUtil from "@/assets/js/components/util"
import ApiService from "@/core/services/api.service"
import Vue from "vue"

export default {
    name: "product_page",
    data() {
        return {
            not_found: false,
            default_image: "media/images/placeholder.png",
            iframeUpdate: false,
            iframeLink: `https://product.xiphoo.com${process.env.VUE_APP_PRODUCT_PAGE_PATH}?productId=${this.$route.params.id}`,
            current_modal_values: {
                id: null,
                name: "",
                image: "",
                text: "",
                link: "",
                type: "",
                options: {
                    color: false,
                    size: false,
                    model: false,
                    material: false,
                    washing_instruction: false,
                },
                css_properties: {},
            },
            edit_modal_visible: false,
            new_image: {},
            old_image: "",
            product_page: {
                general: {
                    name: "Allgemein",
                    type: "GENERAL",
                    data: [],
                },
                text: {
                    name: "Textbausteine",
                    type: "TEXT",
                    data: [],
                },
                buttons: {
                    name: "Buttons",
                    type: "BUTTON",
                    data: [],
                },
            },
        }
    },
    mounted() {
        this.$store.dispatch(ADD_BREADCRUMB, [
            {
                title: this.$t("PRODUCT_PAGE.TITLE"),
                route: `/product_pages/${this.$route.params.id}/product_page`,
            },
        ])
        ApiService.get(
            "product_page_meta/" + this.$route.params.id + "/product_page"
        )
            .then((data) => {
                this.mapDataToTable(data)
            })
            .catch(() => {
                this.not_found = true
                this.$store.dispatch(SET_BREADCRUMB, [])
            })
    },
    methods: {
        editItem(item) {
            ;(this.current_modal_values = {
                id: item.id,
                name: item.name,
                image: item.image,
                text: item.value,
                link: item.link,
                type: item.type,
                options: item.options
                    ? {
                          ...item.options,
                          material: item.options.material
                              ? item.options.material
                              : false,
                          washing_instruction: item.options.washing_instruction
                              ? item.options.washing_instruction
                              : false,
                      }
                    : {},
                css_properties: item.css_properties ? item.css_properties : {},
            }),
                (this.edit_modal_visible = true)
        },
        mapDataToTable(data) {
            this.product_page.buttons.data = []
            this.product_page.text.data = []
            this.product_page.general.data = []
            data.data.map((item) => {
                item = {
                    ...item,
                    css_properties: JSON.parse(atob(item.css_properties)),
                }
                if (item.type === "BUTTON") {
                    this.product_page.buttons.data.push(item)
                } else if (item.type === "TEXT") {
                    this.product_page.text.data.push(item)
                } else if (item.type === "COLOR_OPTION") {
                    this.product_page.general.data.push({
                        ...item,
                        options: JSON.parse(atob(item.value)),
                    })
                } else {
                    this.product_page.general.data.push(item)
                }
                this.old_image = item.image
            })
            this.product_page.general.data.sort((a, b) => {
                // First the Brand Logo, then the Background, then the Product Information
                if (a.type === "BRAND_LOGO") {
                    return -1 // Brand-Logo zuerst
                } else if (b.type === "BRAND_LOGO") {
                    return 1
                } else if (a.type === "BACKGROUND") {
                    return -1 // Background an zweiter Stelle
                } else if (b.type === "BACKGROUND") {
                    return 1
                } else {
                    return 0 // Produktinformationen bleiben unverändert an letzter Stelle
                }
            })
            return data
        },
        confirmDelete(id) {
            if (id !== null) {
                ApiService.delete(
                    "product_page_meta/" +
                        this.$route.params.id +
                        "/product_page/" +
                        id
                )
                    .then(() => {
                        for (const key in this.product_page) {
                            if (this.product_page[key].data) {
                                const p_index = this.product_page[
                                    key
                                ].data.findIndex((p_item) => p_item.id === id)
                                if (p_index !== -1) {
                                    this.product_page[key].data.splice(
                                        p_index,
                                        1
                                    )
                                }
                            }
                        }
                        this.iframeUpdate = !this.iframeUpdate
                    })
                    .catch(() => {
                        //console.log(err)
                    })
            }
        },
        saveItem() {
            if (this.current_modal_values.type === "COLOR_OPTION") {
                this.current_modal_values.text = btoa(
                    JSON.stringify({
                        color: this.current_modal_values.options.color,
                        size: this.current_modal_values.options.size,
                        model: this.current_modal_values.options.model,
                        material: this.current_modal_values.options.material,
                        washing_instruction:
                            this.current_modal_values.options
                                .washing_instruction,
                    })
                )
            }
            if (this.current_modal_values.id !== null) {
                // UPDATE METHOD
                ApiService.put(
                    "product_page_meta/" +
                        this.$route.params.id +
                        "/product_page/" +
                        this.current_modal_values.id,
                    {
                        name: this.current_modal_values.name,
                        value: this.current_modal_values.text,
                        link: this.current_modal_values.link,
                        css_properties: btoa(
                            JSON.stringify(
                                this.current_modal_values.css_properties
                            )
                        ),
                    }
                )
                    .then((data) => this.mapDataToTable(data))
                    .then(() => this.saveImage())
                    .then((data) => {
                        this.iframeUpdate = !this.iframeUpdate
                        if (this.new_image) {
                            this.new_image = {}
                        }
                        if (data.data.image) {
                            if (
                                this.current_modal_values.type === "BRAND_LOGO"
                            ) {
                                const p_index =
                                    this.product_page.general.data.findIndex(
                                        (p_item) =>
                                            p_item.id ===
                                            this.current_modal_values.id
                                    )
                                Vue.set(
                                    this.product_page.general.data,
                                    p_index,
                                    {
                                        ...this.product_page.general.data[
                                            p_index
                                        ],
                                        image: data.data.image,
                                    }
                                )
                            } else if (
                                this.current_modal_values.type === "BUTTON"
                            ) {
                                const p_index =
                                    this.product_page.buttons.data.findIndex(
                                        (p_item) =>
                                            p_item.id ===
                                            this.current_modal_values.id
                                    )
                                Vue.set(
                                    this.product_page.buttons.data,
                                    p_index,
                                    {
                                        ...this.product_page.buttons.data[
                                            p_index
                                        ],
                                        image: data.data.image,
                                    }
                                )
                            } else if (
                                this.current_modal_values.type === "BACKGROUND"
                            ) {
                                const p_index =
                                    this.product_page.general.data.findIndex(
                                        (p_item) =>
                                            p_item.id ===
                                            this.current_modal_values.id
                                    )
                                Vue.set(
                                    this.product_page.general.data,
                                    p_index,
                                    {
                                        ...this.product_page.general.data[
                                            p_index
                                        ],
                                        image: data.data.image,
                                    }
                                )
                            }
                        }
                    })
                    .catch(() => {
                        //console.log(err)
                    })
            } else {
                // POST Method
                ApiService.post(
                    "product_page_meta/" +
                        this.$route.params.id +
                        "/product_page",
                    {
                        name: this.current_modal_values.name,
                        type: this.current_modal_values.type,
                        value: this.current_modal_values.text,
                        link: this.current_modal_values.link,
                        css_properties: btoa(
                            JSON.stringify(
                                this.current_modal_values.css_properties
                            )
                        ),
                    }
                )
                    .then((data) => this.mapDataToTable(data))
                    .then((data) => {
                        const item = data.data.find((item) => {
                            if (item.name === this.current_modal_values.name) {
                                return true
                            }
                        })
                        this.current_modal_values.id = item.id
                        return this.saveImage(item.id)
                    })
                    .then((data) => {
                        this.iframeUpdate = !this.iframeUpdate
                        if (this.new_image) {
                            this.new_image = {}
                        }
                        if (data.data.image) {
                            if (
                                this.current_modal_values.type === "BRAND_LOGO"
                            ) {
                                const p_index =
                                    this.product_page.general.data.findIndex(
                                        (p_item) =>
                                            p_item.id ===
                                            this.current_modal_values.id
                                    )
                                Vue.set(
                                    this.product_page.general.data,
                                    p_index,
                                    {
                                        ...this.product_page.general.data[
                                            p_index
                                        ],
                                        image: data.data.image,
                                    }
                                )
                            } else if (
                                this.current_modal_values.type === "BUTTON"
                            ) {
                                const p_index =
                                    this.product_page.buttons.data.findIndex(
                                        (p_item) =>
                                            p_item.id ===
                                            this.current_modal_values.id
                                    )
                                Vue.set(
                                    this.product_page.buttons.data,
                                    p_index,
                                    {
                                        ...this.product_page.buttons.data[
                                            p_index
                                        ],
                                        image: data.data.image,
                                    }
                                )
                            } else if (
                                this.current_modal_values.type === "BACKGROUND"
                            ) {
                                const p_index =
                                    this.product_page.general.data.findIndex(
                                        (p_item) =>
                                            p_item.id ===
                                            this.current_modal_values.id
                                    )
                                Vue.set(
                                    this.product_page.general.data,
                                    p_index,
                                    {
                                        ...this.product_page.general.data[
                                            p_index
                                        ],
                                        image: data.data.image,
                                    }
                                )
                            }
                        }
                    })
                    .catch(() => {
                        //console.log(err)
                    })
            }
            this.edit_modal_visible = false
        },
        saveImage(id) {
            if (this.new_image.data) {
                return ApiService.put(
                    "product_page_meta/" +
                        this.$route.params.id +
                        "/product_page/" +
                        (this.current_modal_values.id
                            ? this.current_modal_values.id
                            : id) +
                        "/image",
                    this.new_image
                )
            } else if (!this.current_modal_values.image && this.old_image) {
                return ApiService.delete(
                    "product_page_meta/" +
                        this.$route.params.id +
                        "/product_page/" +
                        (this.current_modal_values.id
                            ? this.current_modal_values.id
                            : id) +
                        "/image"
                )
            } else {
                return Promise.resolve(true)
            }
        },
        changedImage(event) {
            const file = event.target.files[0]
            const reader = new FileReader()
            reader.onload = (event) => {
                this.current_modal_values.image = event.target.result
                this.new_image.data = event.target.result.replace(
                    /^data:image\/\w+;base64,/,
                    ""
                )
                this.new_image.mime_type = file.type
            }
            reader.readAsDataURL(file)
        },
        clearImage() {
            this.current_modal_values.image = ""
            this.new_image = {}
        },
        checkIfColorOptionExists() {
            return this.product_page.general.data.some(
                (item) => item.type === "COLOR_OPTION"
            )
        },
        checkIfBrandLogoExists() {
            return this.product_page.general.data.some(
                (item) => item.type === "BRAND_LOGO"
            )
        },
        checkIfBackgroundExists() {
            return this.product_page.general.data.some(
                (item) => item.type === "BACKGROUND"
            )
        },
        newItem(type) {
            const default_value = {
                id: null,
                name: "",
                type: type,
                value: "",
                link: "",
                image: "",
                css_properties: {
                    fontFamily: "Arial, sans-serif",
                    textAlignment: "center",
                    textColor: "#000000",
                    backgroundColor: "#ffffff",
                },
            }
            if (type === "BRAND_LOGO") {
                default_value.name = this.$t("PRODUCT_PAGE.BRAND_LOGO_NAME")
            }
            if (type === "COLOR_OPTION") {
                default_value.name = this.$t("PRODUCT_PAGE.COLOR_OPTION_NAME")
                default_value.options = {
                    color: false,
                    size: false,
                    model: false,
                    material: false,
                    washing_instruction: false,
                }
            }
            if (type === "BACKGROUND") {
                ;(default_value.name = this.$t("PRODUCT_PAGE.BACKGROUND_NAME")),
                    (default_value.css_properties = {})
                default_value.value = "#ffffff"
            }
            this.editItem(default_value)
        },
        setTextAlignment(align) {
            if (
                this.current_modal_values.css_properties.textAlignment !== align
            ) {
                Vue.set(
                    this.current_modal_values.css_properties,
                    "textAlignment",
                    align
                )
            }
        },
    },
}
</script>
